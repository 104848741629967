import gql from 'graphql-tag';

const paymentCheckoutMutation = gql`
  mutation paymentCheckout(
    $organisationId: String!
    $quantity: Int!
    $originUrl: String!
  ) {
    paymentCheckout(
      organisationId: $organisationId
      quantity: $quantity
      originUrl: $originUrl
    ) {
      orderId
      status
      checkoutUrl
    }
  }
`;

export default (organisationId: string) => ({
  mutation: paymentCheckoutMutation,
  module: 'payments',
  buildVariables: ({
    quantity,
    originUrl
  }: {
    organisationId: string;
    quantity: number;
    originUrl: string;
  }) => ({
    organisationId,
    quantity,
    originUrl
  })
});
