import { RequestProvider, AuthorisationConsumer } from '@shootsta/common-react';
import { getPaymentInvoiceQuery } from '../api';
import PaymentStatusModalComponent from '../components/PaymentStatusModal';

interface PaymentStatusModal {
  visible: boolean;
  history: any;
}

const PaymentStatusModal = (props: PaymentStatusModal) => {
  return (
    <AuthorisationConsumer>
      {({ selectedOrganisation: { organisationId } }: any) => (
        <RequestProvider
          requests={{
            getPaymentInvoice: getPaymentInvoiceQuery(organisationId)
          }}
        >
          {({ getPaymentInvoice }: { getPaymentInvoice: Function }) => (
            <PaymentStatusModalComponent
              getPaymentInvoice={getPaymentInvoice}
              {...props}
            />
          )}
        </RequestProvider>
      )}
    </AuthorisationConsumer>
  );
};

export default PaymentStatusModal;
