import React from 'react';
import _isEmpty from 'lodash.isempty';
import {
  FormModal,
  Icon,
  Text,
  Row,
  Divider,
  Button,
  getBucket
} from '@shootsta/common-react';

import '../styles/_flex-purchase-payment-success-modal.scss';

const FlexPurchasePaymentSuccessModal = ({
  visible,
  contractItemBalance,
  purchasedCredit,
  onClose,
  onViewInvoice
}: {
  visible: boolean;
  purchasedCredit: number;
  contractItemBalance: any;
  onClose: (args: boolean) => void;
  onViewInvoice: () => void;
}) => {
  function calculatePrevCreditBalance() {
    if (_isEmpty(contractItemBalance)) return;

    const allCreditTypeBalances = getBucket(contractItemBalance).filter(
      (balance: any) => balance.product
    );

    if (_isEmpty(allCreditTypeBalances)) return 0;

    const totalCreditsBalance = allCreditTypeBalances
      .map((item: any) => item.credit)
      .reduce((prev: number, next: number) => prev + next);

    const totalTopupCreditsBalance = allCreditTypeBalances
      .map((item: any) => item.topUpCredit)
      .reduce((prev: number, next: number) => prev + next);

    return totalCreditsBalance + totalTopupCreditsBalance;
  }

  const prevCreditBalance = calculatePrevCreditBalance();

  const totalCredits = Number(prevCreditBalance) + Number(purchasedCredit);

  const onCloseHandler = () => onClose(false);

  return (
    <FormModal
      visible={visible}
      showExtraButtons
      onSave={onCloseHandler}
      onClose={onCloseHandler}
      showCancelBtn={false}
      saveText="Close"
      footerExtraButtons={
        <Button onClick={onViewInvoice} type="TERTIARY">
          View invoice
        </Button>
      }
    >
      <div className="flex-purchase-payment-success-modal">
        <div className="flex-purchase-payment-success-modal__heading">
          <Icon name="checkedFilled" color="monkey" size="40" />
          <Text
            className="flex-purchase-payment-success-modal__heading__title"
            heading2
          >
            Credit top-up successful!
          </Text>
          <Text className="flex-purchase-payment-success-modal__heading__subtitle">
            Your payment was completed successfully! Feel free to contact us if
            you have any questions.
          </Text>
        </div>

        <div className="flex-purchase-payment-success-modal__content">
          <Row nogutter justify="between">
            <Text>Previous Balance</Text>
            <Text>{prevCreditBalance} credits</Text>
          </Row>
          <Row nogutter justify="between">
            <Text>Top Up</Text>
            <Text>+ {purchasedCredit} credits</Text>
          </Row>
          <Divider />
          <Row nogutter justify="between">
            <Text highlighted>Current Balance</Text>
            <Text highlighted>{totalCredits} credits</Text>
          </Row>
        </div>
      </div>
    </FormModal>
  );
};

export default FlexPurchasePaymentSuccessModal;
