import { useEffect, useMemo, useState } from 'react';
import _isEmpty from 'lodash.isempty';
import {
  FormModal,
  Image,
  Input,
  Text,
  getBucket
} from '@shootsta/common-react';
import { CURRENCY_SYMBOL } from '@shootsta/common-domain-data';

import '../styles/_top-up-credits-modal.scss';

interface ContractType {
  ORGANISATION_ID: string;
  CONTRACT_LINE_ITEMS: {
    STORAGE: number;
    SEATS: string | number;
    CREDITS: number;
  };
  RESET_PERIOD: string;
  BILLING_ENTITY_CODE: string;
  TOP_UP_CREDIT_VALUE: number;
  SALESFORCE_ACCOUNT_ID: string;
  CONTENT_REGION: string;
  TIER: 'PAID' | 'FREE' | 'TRIAL';
  START_DATE: string;
  END_DATE: string;
  MAX_PRODUCT_END_DATE: string;
}

interface ContractItemBalanceType {
  type: string;
  product: string;
  value: number;
}

interface TopupCreditsModalProps {
  orgName: string;
  visible: boolean;
  onClose: () => void;
  orgImageUrl: string;
  contractItemBalance: ContractItemBalanceType[];
  getContracts: ({
    product,
    status
  }: {
    product: string;
    status: string;
  }) => Promise<{ data: any; error: any }>;
  paymentCheckout: ({
    quantity,
    originUrl
  }: {
    quantity: number;
    originUrl: string;
  }) => Promise<{ data: any; error: any }>;
}

function TopupCreditsModal({
  orgName,
  visible,
  onClose,
  orgImageUrl,
  contractItemBalance,
  getContracts,
  paymentCheckout
}: TopupCreditsModalProps) {
  const [contract, setContract] = useState<ContractType>({});
  const [credits, setCredits] = useState<number>(0);

  const totalBalance = useMemo(() => {
    if (_isEmpty(contractItemBalance)) return 0;

    const allCreditTypeBalances = getBucket(contractItemBalance).filter(
      (balance: any) => balance.product
    );

    if (_isEmpty(allCreditTypeBalances)) return 0;

    const totalCreditsBalance = allCreditTypeBalances
      .map((item: any) => item.credit)
      .reduce((prev: number, next: number) => prev + next);

    const totalTopupCreditsBalance = allCreditTypeBalances
      .map((item: any) => item.topUpCredit)
      .reduce((prev: number, next: number) => prev + next);

    return totalCreditsBalance + totalTopupCreditsBalance;
  }, [contractItemBalance]);

  const fetchContractDetails = async () => {
    const { data, error } = await getContracts({
      product: 'WORKSPACE',
      status: 'ACTIVE'
    });

    if (!data || error) return;

    if (data?.getContracts?.[0]) {
      setContract(data.getContracts[0].Payload?.subscription);
    }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key !== 'Enter') return;

    event.preventDefault();

    await checkout();
  };

  const checkout = async () => {
    const { data, error } = await paymentCheckout({
      quantity: Number(credits),
      originUrl: window.location.href
    });

    if (!data || error) return;

    window.location.href = data.paymentCheckout.checkoutUrl;
  };

  function renderCreditPrice(amount: number, entityCode: string): string {
    const currency = CURRENCY_SYMBOL[entityCode];

    if (amount > 0 && !currency) {
      return `$${amount.toFixed(2)}`;
    }

    if (amount > 0 && currency) {
      return `${currency} ${amount.toFixed(2)}`;
    }

    return '-';
  }

  const pricePerCredit = contract?.TOP_UP_CREDIT_VALUE || 0;
  const billingEntityCode = contract?.BILLING_ENTITY_CODE || '-';

  const calculatedDueAmount = useMemo(
    () => (Number(credits) || 0) * pricePerCredit,
    [credits, pricePerCredit]
  );

  useEffect(() => {
    fetchContractDetails();
  }, []);

  return (
    <FormModal
      key="top-up-credit-modal"
      visible={visible}
      title="Top up Credits"
      onSave={checkout}
      saveDisabled={!credits}
      onClose={onClose}
      saveText="Proceed to Payment"
    >
      <div className="top-up-credits-modal">
        <div className="top-up-credits-modal__balance">
          <Image
            width={48}
            height={48}
            placeholderText={orgName}
            placeholderTextSize={15}
            defaultIconColour="regent"
            circular
            alt="org-logo"
            imageUrl={orgImageUrl}
          />
          <div className="top-up-credits-modal__balance__title">
            <Text heading5 className="current-balance-title">
              Current Balance
            </Text>
            <Text className="credits-remaining" body>
              {totalBalance} credits remaining
            </Text>
          </div>
        </div>
        <div className="top-up-credits-modal__purchase-box">
          <div className="top-up-credits-modal__purchase-box__quantity">
            <Text heading5>Purchase Quantity</Text>
            <div className="top-up-credits-modal__purchase-box__quantity__input">
              <Input
                type="number"
                min={0}
                onKeyDown={handleKeyDown}
                placeholder="e.g. 10"
                label="Number of credits"
                value={credits}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCredits(e.target.value)
                }
              />
              <span className="credit-suffix">credits</span>
            </div>
          </div>
          <div className="top-up-credits-modal__purchase-box__summary">
            <Text heading5>Summary</Text>
            <Text className="price-per-credit" body>
              ${pricePerCredit}/credit
            </Text>
            <div className="top-up-credits-modal__purchase-box__summary__credit-amount">
              <Text>{credits || 0} credits</Text>
              <Text timestamp className="credit-price">
                {renderCreditPrice(calculatedDueAmount, billingEntityCode)}
              </Text>
            </div>
            <hr className="separator" />
          </div>
          <div className="top-up-credits-modal__purchase-box__amount-due">
            <Text heading5>Amount Due</Text>
            <Text heading5>
              {renderCreditPrice(calculatedDueAmount, billingEntityCode)}
            </Text>
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default TopupCreditsModal;
