import React, { useEffect, useState, useRef } from 'react';
import _isEmpty from 'lodash.isempty';
import { FormModal, Loader, useSocketContext } from '@shootsta/common-react';
import {
  FlexPurchasePaymentSuccessModal,
  PaymentFailedModal,
  PaymentSuccessfulModal,
  PAYMENT_TYPES
} from '@core/shared';

interface PaymentStatusModalProps {
  history: any;
  getPaymentInvoice: Function;
}

interface PaymentSuccessEventType {
  orderId: string;
  status: string;
  totalCreditPurchased: number;
}

interface LocationPaymentParams {
  paymentStatus: string | null;
  orderId: string | null;
  type: string | null;
}

enum PaymentStatusEnum {
  PROCESSING = 'processing',
  SUCCESS = 'success',
  CANCELLED = 'cancelled'
}

function parsePaymentSearchParams(search: LocationPaymentParams) {
  const defaultParams = {
    status: null,
    orderId: null,
    type: null
  };

  if (_isEmpty(search)) {
    return defaultParams;
  }

  const searchParams = new URLSearchParams(search);

  const status = searchParams.get('paymentStatus');
  const orderId = searchParams.get('orderId');
  const type = searchParams.get('type');

  return {
    status,
    orderId,
    type
  };
}

function PaymentStatusModal({
  history,
  getPaymentInvoice
}: PaymentStatusModalProps) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { socket } = useSocketContext();

  const {
    location: { search }
  } = history;

  const {
    status,
    orderId: orderIdParam,
    type
  } = parsePaymentSearchParams(search);

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusEnum | null>(
    status as PaymentStatusEnum
  );
  const [orderId, setOrderId] = useState<string | null>(orderIdParam);
  const [orderType, setOrderType] = useState<string | null>(type);
  const [purchasedCredit, setPurchasedCredit] = useState(0);

  function onClosePaymentModal() {
    setPaymentStatus(null);
  }

  function handleOrderEvent(order: PaymentSuccessEventType) {
    const { orderId: receivedOrderId, totalCreditPurchased } = order;

    if (orderId !== receivedOrderId) {
      return;
    }

    setPaymentStatus(PaymentStatusEnum.SUCCESS);
    setPurchasedCredit(totalCreditPurchased);

    if (!timeoutRef.current) {
      return;
    }

    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }

  async function onClickViewInvoice() {
    const { data } = await getPaymentInvoice({ orderId });
    const { invoiceUrl } = data?.getPaymentInvoice?.[0] || {};

    if (invoiceUrl) window.open(invoiceUrl, '_blank');

    onClosePaymentModal();
  }

  function removeUrlParams(param: string) {
    if (_isEmpty(search)) return;

    const queryParams = new URLSearchParams(search);
    queryParams.delete(param);

    const newUrl = {
      pathname: history.location.pathname,
      search: queryParams.toString(), // Include remaining query parameters
      hash: history.location.hash // Preserve the hash
    };

    history.replace(newUrl);
  }

  useEffect(() => {
    socket.connect();

    const onConnect = () => console.log('Socket connected');
    const onDisconnect = () => console.log('Socket disconnected');

    socket.on('connect', onConnect);
    socket.on('connect_error', onDisconnect);
    socket.on('error', onDisconnect);
    socket.on('disconnect', onDisconnect);

    socket.on(`orderId|${orderId}`, (order: PaymentSuccessEventType) => {
      handleOrderEvent(order);
    });

    return () => {
      socket.disconnect();

      if (!timeoutRef.current) {
        return;
      }
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (_isEmpty(search)) return;

    const { status, orderId, type } = parsePaymentSearchParams(search);

    if (type) {
      setOrderType(type);
      removeUrlParams('type');
    }

    if (orderId) {
      setOrderId(orderId);
      removeUrlParams('orderId');
    }

    if (!status) {
      return;
    }

    if (status === PaymentStatusEnum.SUCCESS) {
      setPaymentStatus(PaymentStatusEnum.PROCESSING);
      removeUrlParams('paymentStatus');

      timeoutRef.current = setTimeout(
        () => window.location.reload(),
        2 * 60 * 1000
      );
      return;
    }

    setPaymentStatus(status as PaymentStatusEnum);
    removeUrlParams('paymentStatus');
  }, [search]);

  if (paymentStatus === PaymentStatusEnum.PROCESSING) {
    return (
      <FormModal visible hideFooter showHeaderCloseButton={false}>
        <Loader
          hasNoBeforeShowTimeout
          text="Please wait a moment while we securely process your payment. Do not close or refresh the page."
          caption={null}
        />
      </FormModal>
    );
  }

  if (paymentStatus === PaymentStatusEnum.SUCCESS) {
    if (orderType === PAYMENT_TYPES.FLEX) {
      return (
        <FlexPurchasePaymentSuccessModal
          visible
          purchasedCredit={purchasedCredit}
          onViewInvoice={onClickViewInvoice}
          onClose={onClosePaymentModal}
        />
      );
    }

    if (orderType === PAYMENT_TYPES.PROJECT_COMPLETED) {
      return (
        <PaymentSuccessfulModal
          visible
          onViewInvoice={onClickViewInvoice}
          onClose={onClosePaymentModal}
        />
      );
    }

    if (orderType === PAYMENT_TYPES.PROJECT_SUBMISSION) return;
  }

  if (paymentStatus === PaymentStatusEnum.CANCELLED) {
    return <PaymentFailedModal visible onClose={onClosePaymentModal} />;
  }

  return null;
}

export default PaymentStatusModal;
