import { Tabs } from '@shootsta/common-react';
import '../styles/_tabsV2.scss';

const TabsV2 = (props) => (
    <div className='tabsV2'>
        <Tabs {...props} />
    </div>
);

export default TabsV2;
