import gql from 'graphql-tag';

const getPaymentInvoiceQuery = gql`
  query GetPaymentInvoice(
    $videoId: String
    $organisationId: String!
    $orderId: String
  ) {
    getPaymentInvoice(
      videoId: $videoId
      organisationId: $organisationId
      orderId: $orderId
    ) {
      invoiceUrl
      invoiceId
      orderId
      refundedAt
      createdAt
    }
  }
`;

const getPaymentInvoice = (organisationId: string) => ({
  query: getPaymentInvoiceQuery,
  module: 'payments',
  ignoreCache: true,
  buildVariables: ({
    invoiceId,
    orderId
  }: {
    invoiceId: string;
    orderId: string;
  }) => ({
    invoiceId,
    organisationId,
    orderId
  })
});

export default getPaymentInvoice;
