import { CreditsConsumer } from '@shootsta/common-react';
import FlexPurchasePaymentSuccessModalComponent from '../components/FlexPurchasePaymentSuccessModal';

interface FlexPurchasePaymentSuccessModalProps {
  visible: boolean;
  orderId: string;
  purchasedCredit: number;
  onClose: (args: boolean) => void;
  onViewInvoice: () => void;
}

function FlexPurchasePaymentSuccessModal(
  props: FlexPurchasePaymentSuccessModalProps
) {
  return (
    <CreditsConsumer>
      {({ state: { contractItemBalance } }) => (
        <FlexPurchasePaymentSuccessModalComponent
          contractItemBalance={contractItemBalance}
          {...props}
        />
      )}
    </CreditsConsumer>
  );
}

export default FlexPurchasePaymentSuccessModal;
