import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormModal, Icon, Text } from '@shootsta/common-react';

import '../styles/_project-submission-payment-success-modal.scss';

interface ProjectSubmissionPaymentSuccessModalProps
  extends RouteComponentProps {
  visible: boolean;
  onViewInvoice: () => void;
}

function ProjectSubmissionPaymentSuccessModal({
  visible,
  history,
  onViewInvoice
}: ProjectSubmissionPaymentSuccessModalProps) {
  const onSave = () => history.push('/pro');

  return (
    <FormModal
      visible={visible}
      saveText="Back to projects"
      size="MD"
      closeText="View Invoice"
      onSave={onSave}
      onClose={onViewInvoice}
    >
      <div className="project-submission-payment-confirmation-modal">
        <Icon name="checkedFilled" color="monkey" size="40" />
        <Text heading3>
          Thank you for your payment, your project has been submitted!
        </Text>
        <Text body>
          What&apos;s next? Our production team is reviewing your project brief
          to ensure we have everything we need to start editing your master
          version. Sit back and relax! We&apos;ll reach out if we have any
          questions.
        </Text>
      </div>
    </FormModal>
  );
}

export default withRouter(ProjectSubmissionPaymentSuccessModal);
